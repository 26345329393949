import { useEffect, useState } from "react";
import "./App.css";
import "./index.css";

const textStyle = {
  color: "white",
  fontSize: "1.5rem",
  textShadow: `
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000
  `,
};

function App() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    "https://suilly-media.s3.eu-central-1.amazonaws.com/gangster-suilly.webp",
    "https://suilly-media.s3.eu-central-1.amazonaws.com/crying.png",
    "https://suilly-media.s3.eu-central-1.amazonaws.com/chainz.webp",
    "https://suilly-media.s3.eu-central-1.amazonaws.com/devil.webp",
    "https://suilly-media.s3.eu-central-1.amazonaws.com/gangster-suilly.webp",
    "https://suilly-media.s3.eu-central-1.amazonaws.com/halo.webp",
    "https://suilly-media.s3.eu-central-1.amazonaws.com/head-shot.png",
    "https://suilly-media.s3.eu-central-1.amazonaws.com/heart-eyes.png",
    "https://suilly-media.s3.eu-central-1.amazonaws.com/incredible-suilly.webp",
    "https://suilly-media.s3.eu-central-1.amazonaws.com/laser-eyes.jpg",
    "https://suilly-media.s3.eu-central-1.amazonaws.com/minecraft-sunglasses.jpg",
    "https://suilly-media.s3.eu-central-1.amazonaws.com/rose.webp",
    "https://suilly-media.s3.eu-central-1.amazonaws.com/scarf.webp",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const toggleMute = () => {
    const audioElem = document.getElementById("myAudio");
    audioElem.muted = !audioElem.muted;
    audioElem.muted ? audioElem.pause() : audioElem.play();
  };

  return (
    <div className="min-h-screen bg-repeat bg-[length:75px_75px] bg-center bg-[url('https://suilly-media.s3.eu-central-1.amazonaws.com/Screenshot+2024-09-23+at+23.23.31.png')] p-8 text-center flex justify-center items-center">
      <div className="max-w-2xl w-full">
        {" "}
        {/* Navigation */}
        <nav className="sticky top-8 bg-[#ffffff] p-4 text-[#2c5782] flex justify-center space-x-4 sm:space-x-12 rounded-xl border-solid border-[4px] border-[#2c5782]">
          <a
            href="https://hop.ag/swap/SUI-0x2b9f8e475279e58dab63683ef86074891bce00a7d6945546c66b5cdb9656a9bc%3A%3Asuilly%3A%3ASUILLY"
            className="font-bold text-base sm:text-lg md:text-xl hover:underline"
          >
            BUY
          </a>
          <a
            href="https://x.com/Suilly_Sui"
            className="font-bold text-base sm:text-lg md:text-xl hover:underline"
          >
            TWITTER
          </a>
          <a
            href="https://t.me/suilly_onsui"
            className="font-bold text-base sm:text-lg md:text-xl hover:underline"
          >
            TELEGRAM
          </a>
          <a
            href="https://dexscreener.com/sui/0x0f4260137309fbedc728edb4171e8d194752bd9092251467cc0b09bc2c83e423"
            className="font-bold text-base sm:text-lg md:text-xl hover:underline"
          >
            CHART
          </a>
        </nav>
        {/* Main Content */}
        <div className="flex flex-col items-center mt-16">
          <img
            src="https://suilly-media.s3.eu-central-1.amazonaws.com/suilly-image.jpeg"
            alt="Suilly"
            className="rounded-3xl w-80 h-80 object-cover border-solid border-[4px] border-white"
          />
          <h1
            className="text-2xl font-bold mt-16 text-white outline-white outline-2"
            style={textStyle}
          >
            $SUILLY IS THE CUTEST ANIMAL ON SUI, ON A MISSION TO BE WORTH
            SUILLYONS AND SUILLYONS!
          </h1>
          <a
            href="https://hop.ag/swap/SUI-0x2b9f8e475279e58dab63683ef86074891bce00a7d6945546c66b5cdb9656a9bc%3A%3Asuilly%3A%3ASUILLY"
            className="mt-6 bg-[#2c5782] p-4 text-[#fff] hover:text-[#2c5782] hover:bg-[#fff] py-4 px-12 rounded-lg font-bold border-solid border-[4px] border-[#fff] hover:border-[#2c5782]"
          >
            BUY SOME $SUILLY!
          </a>
        </div>
        {/* Code Section */}
        {/* <div className="mt-12 text-lg font-bold text-white">
          0x8c47c0bde84b7056520a44f46c56383e714cc9b6a55e919d8736a34ec7ccb533::suilly::SUILLY
        </div> */}
        {/* External Links Section */}
        {/* <div className="flex flex-col items-center mt-4">
          <div className="grid grid-cols-3 gap-12 mt-12">
            <a
              href="https://suilly.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/icon-tg.png"
                alt="Telegram"
                className="w-12 h-12 rounded-md"
              />
            </a>
            <a
              href="https://suilly.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/icon-tg.png"
                alt="X"
                className="w-12 h-12 rounded-md"
              />
            </a>
            <a
              href="https://suilly.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/icon-tg.png"
                alt="Dex Screener"
                className="w-12 h-12 rounded-md"
              />
            </a>
            <a
              href="https://suilly.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/icon-tg.png"
                alt="Dextools"
                className="w-12 h-12 rounded-md"
              />
            </a>
            <a
              href="https://suilly.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/icon-tg.png"
                alt="Birdeye"
                className="w-12 h-12 rounded-md"
              />
            </a>
            <a
              href="https://suilly.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/icon-tg.png"
                alt="CoinGecko"
                className="w-12 h-12 rounded-md"
              />
            </a>
          </div>
        </div> */}
        {/* Slideshow */}
        <div className="flex flex-col items-center mt-16">
          <h2 className="text-xl font-bold text-white" style={textStyle}>
            SHARE YOUR $SUILLY MEME!
          </h2>
          <img
            src={images[currentImageIndex]}
            alt="Meme"
            className="rounded-3xl w-80 h-80 object-cover border-solid border-[4px] border-white mt-4"
          />
        </div>
      </div>
    </div>
  );
}

export default App;
